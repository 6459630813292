jQuery(document).ready(function () {

	if (window.innerWidth <= 768) {
		jQuery('.btn_business').click(function (e) {
			e.preventDefault(); // stops link from making page jump to the top
			e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
			jQuery(".btn_business .sub-menu").toggleClass("show");
			jQuery(".btn_business").find("i").toggleClass("down");
			jQuery(".btn_business").toggleClass("selected");
			jQuery(".btn_categorias").removeClass("selected");
			jQuery(".btn_categorias").find("i").removeClass("down");
			jQuery(".menu_categorias").removeClass("show");


		});

		jQuery(".btn_categorias").click(function (e) {
			if (jQuery(".btn_business .sub-menu").hasClass("show")) {
				jQuery(".btn_business .sub-menu").toggleClass("show");
				jQuery(".btn_business").find("i").toggleClass("down");
				jQuery(".btn_business").toggleClass("selected");
			}
		});
	}


	if (jQuery("body").hasClass("home")) {
		let welcome = "Welcome";

		if (window.location.href.includes("/pt/") || window.location.href.includes("/br/")) {
			welcome = "Bem-vindo"
		}

		if (window.location.href.includes("/es/")) {
			welcome = "Bienvenido"
		}

		let span = "<span class='welcome'>" + welcome + "</span>";
		jQuery(".inside_cat_header ").prepend(span);
	}

	// footer language selector
	jQuery(".footer_language_list select").unbind("change");
	jQuery(".footer_language_list select").change(function () {
		let url = jQuery('option:selected', this).attr('url');
		if (url) { // require a URL
			window.location = url; // redirect
		}
	});
});
